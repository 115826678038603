import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import ShareIcon from '@mui/icons-material/Share'
import { makeStyles } from '@mui/styles'
import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
// import { authContext } from 'contexts/AuthContext'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import apple_store_badges from '../assets/app-store-badge.png'
import google_play_badges from '../assets/google-play-badge.png'
import Dialog from '@mui/material/Dialog'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import { jobinterviewById } from '../../../services/api/job_application'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { responseToInterview, resheduleinterview } from 'services/api/job_application/index'
import moment from 'moment'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'

const useStyles = makeStyles({
  btnContained: {
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    borderRadius: '100px',
    padding: '8px 20px',

    '&:focus': {
      outline: 'none',
      backgroundColor: '#1B8379',
    },
    '&:hover': {
      outline: 'none',
      backgroundColor: '#1B8379',
    },
  },
  btnShareDesktop: {
    color: '#E2E2E2',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    // justifySelf:"center",
    '&:hover': {
      // color:"#1B8379",
      backgroundColor: '#1B8379',
      // textTransform:"uppercase"
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },

  btn1: {
    color: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    // justifySelf:"center",
    '&:hover': {
      // color:"#1B8379",
      backgroundColor: 'E2E2E2',
      // textTransform:"uppercase"
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
  btn2: {
    color: '#E2E2E2',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    // justifySelf:"center",
    '&:hover': {
      // color:"#1B8379",
      backgroundColor: '#1B8379',
      // textTransform:"uppercase"
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
  btn3: {
    color: '#FFF',
    backgroundColor: '#F6832F',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    // width:"100%",
    // justifySelf:"center",
    '&:hover': {
      // color:"#1B8379",
      backgroundColor: '#F6832F',
      // textTransform:"uppercase"
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },

  btnTextInvitationCancel: {
    color: '#1B8379',
    fontSize: '1.25rem',
    textTransform: 'capitalize',
    width: '40%',
  },

  btnTextInvitationConfirm: {
    color: '#000',
    fontSize: '1.25rem',
    textTransform: 'capitalize',
    width: '40%',
  },
  rescheduleMessage: {
    backgroundColor: '#F5F5F5',
    width: '100%',
    margin: '0 auto',
    borderRadius: 20,
    padding: '10px 10px',
  },
})

const JobInterviewDetails = (props) => {
  const classes = useStyles()
  const param = useParams()
  const [jobInterview, setJobInterview] = useState([])
  const [open, setOpen] = React.useState(false)
  const [copied, setCopied] = useState(false)
  const [openAcceptInvitation, setOpenAcceptInvitation] = React.useState(false)
  const [openSnackInvitation, setOpenSnackInvitation] = useState(false)
  const [snackPropsInvitation, setSnackPropsInvitation] = useState({
    severity: '',
    message: '',
  })
  const [propsInvitationContent, setPropsInvationContent] = useState({
    title: '',
    text: '',
    warning: '',
    status: null,
  })

  const [openReschedule, setOpenReschedule] = React.useState(false)
  const [msgtoEmployer, setMsgtoEmployer] = useState('')
  const [sendMsgtoEmployer, setSendMsgtoEmployer] = useState(false)

  useEffect(() => {
    getJobInterviewById(param.id)
    // getJobApplicationsById(param.id)
  }, [param.id])

  const getJobInterviewById = async (id) => {
    try {
      const response = await jobinterviewById(id)
      // console.log("getJobInterviewById",response.data)
      setJobInterview(response.data)
    } catch (e) {
      // console.log(e)
    }
  }
  // const getJobApplicationsById = async (id) => {

  //     try {
  //     const response =  await jobApplicationsById(id)
  //     console.log(response.data)
  //     setJobApplication(response.data)
  // }
  //     catch(e){
  //         console.log(e)
  //     }

  // }

  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }

  const handleCloseAcceptInvitation = (value) => {
    setOpenAcceptInvitation(false)
  }

  const handleConfirmInvitation = async (id, status) => {
    try {
      // console.log(id,status)
      const result = await responseToInterview(id, status)
      // console.log("responseToInterview",result)
      setSnackPropsInvitation({
        severity: 'success',
        message: result.message,
      })

      await setOpenSnackInvitation(true)
    } catch (e) {
      // alert(e.data.message)
      setSnackPropsInvitation({
        severity: 'error',
        message: e.data.message,
      })
      // console.log(e.data.message)
      await setOpenSnackInvitation(true)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
    setCopied(false)

    // setSelectedValue(value);
  }

  const handleClickOpenAcceptInvitation = () => {
    setPropsInvationContent({
      title: 'Accept Invitation?',
      text: 'Do you want to accept this interview invitation?',
      warning:
        'Failing to attend your interview after accepting this invitation will negatively affect your future applications. ',
      status: 1,
    })

    setOpenAcceptInvitation(true)
  }

  const handleClickOpenDeclineInvitation = () => {
    setPropsInvationContent({
      title: 'Decline Invitation?',
      text: 'Are you sure you want to decline this interview invitation?',
      warning:
        'Declining this invitation will cause your application to be withdrawn. Consider requesting to reschedule instead if you cannot attend the interview on the suggested date.',
      status: 3,
    })

    setOpenAcceptInvitation(true)
  }

  const handleClickOpenReschedule = () => {
    setOpenReschedule(true)
  }

  const handleClickCloseReschedule = (value) => {
    setOpenReschedule(false)
  }

  const handleReschedule = async (data) => {
    try {
      // console.log(data)
      const result = await resheduleinterview(data)
      // console.log("resheduleinterview",result)

      setSnackPropsInvitation({
        severity: 'success',
        message: result.message,
      })

      await setOpenSnackInvitation(true)
    } catch (e) {
      // alert(e.data.message)
      setSnackPropsInvitation({
        severity: 'error',
        message: e.data.message,
      })
      // console.log("resheduler error",e)
      await setOpenSnackInvitation(true)
    }

    setOpenReschedule(false)
  }

  const closeSnackInvitation = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackInvitation(false)
  }

  // Get image file extension
  let re = /(?:\.([^.]+))?$/

  return (
    <div className="h-screen">
      <Snackbar
        open={openSnackInvitation}
        autoHideDuration={3500}
        onClose={closeSnackInvitation}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={closeSnackInvitation}
          severity={snackPropsInvitation.severity}
          variant="filled"
        >
          {snackPropsInvitation.message}
        </Alert>
      </Snackbar>

      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="lg">
        {/* <EmailShareButton url={window.location.href}> Share in Email</EmailShareButton> */}
        <div className="flex flex-col justify-between p-8 item-center h-80 font-poppins lg:w-96">
          <p className="text-lg font-bold text-center">Share this job on social media</p>
          <div className="mb-4">
            <p className="font-bold text-center truncate text-adx-darkGreen">
              {jobInterview.position_title}
            </p>
            <p className="font-bold text-center truncate">{jobInterview.company_name}</p>
          </div>
          <div className="flex justify-between">
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon borderRadius="50%" size={48} />
            </FacebookShareButton>

            <LinkedinShareButton url={window.location.href}>
              <LinkedinIcon borderRadius="50%" size={48} />
            </LinkedinShareButton>

            <TwitterShareButton url={window.location.href}>
              <TwitterIcon borderRadius="50%" size={48} />
            </TwitterShareButton>

            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon borderRadius="50%" size={48} />
            </WhatsappShareButton>
          </div>

          <CopyToClipboard text={window.location.href} onCopy={() => setCopied(true)}>
            <Button className={classes.btnShareDesktop} color="primary">
              Copy to clipboard{' '}
            </Button>
          </CopyToClipboard>
          {copied ? <p className="text-center text-red-500">Copied</p> : null}
        </div>
      </Dialog>

      <Dialog
        onClose={handleCloseAcceptInvitation}
        aria-labelledby="simple-dialog-title"
        open={openAcceptInvitation}
        maxWidth="lg"
      >
        <div className="flex flex-col items-center px-8 text-center h-80 justify-evenly font-poppins lg:w-96">
          <p className="text-xl font-bold underline">{propsInvitationContent.title}</p>
          <p className="text-lg font-bold">{propsInvitationContent.text}</p>
          <p className="text-base italic font-bold text-red-700">
            {propsInvitationContent.warning}
          </p>
          <div className="flex items-center justify-between w-full">
            <Button
              onClick={() => {
                handleCloseAcceptInvitation()
              }}
              className={classes.btnTextInvitationCancel}
            >
              Cancel
            </Button>
            |
            <Button
              onClick={() =>
                handleConfirmInvitation(jobInterview.id, propsInvitationContent.status)
              }
              className={classes.btnTextInvitationConfirm}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        onClose={handleClickCloseReschedule}
        aria-labelledby="simple-dialog-title"
        open={openReschedule}
        maxWidth="lg"
      >
        <div className="flex flex-col items-start px-4 text-center h-96 justify-evenly font-poppins lg:w-96">
          <p className="w-full text-xl font-bold text-center underline">Request to Reschedule? </p>
          <p className="text-base font-bold">
            Please indicate your preferred date & time, as well as your reasons for rescheduling the
            interview in the text box below.
          </p>

          <label className="flex items-start mt-2">
            <div class="relative mr-2 inline-block w-10 select-none transition duration-1000 ease-in">
              <input
                type="checkbox"
                id="msgtoEmp"
                onChange={(e) => setSendMsgtoEmployer(e.target.checked)}
                class="toggle-checkbox absolute block h-6 w-6 cursor-pointer appearance-none rounded-full border-4 bg-white focus:outline-none"
              />
              <label
                for="msgtoEmp"
                class="toggle-label block h-6 cursor-pointer overflow-hidden rounded-full bg-gray-300"
              ></label>
            </div>
          </label>
          <InputBase
            disabled={!sendMsgtoEmployer}
            multiline
            rows={5}
            placeholder="Type your message here"
            className={classes.rescheduleMessage}
            onChange={(e) => {
              setMsgtoEmployer(e.target.value)
            }}
          />
          <div className="flex items-center justify-between w-full">
            <Button
              className={classes.btnTextInvitationCancel}
              onClick={() => {
                handleClickCloseReschedule()
              }}
            >
              Cancel
            </Button>
            |
            <Button
              className={classes.btnTextInvitationConfirm}
              onClick={() => {
                handleReschedule({
                  interview_id: jobInterview.id,
                  interview_status_id: 4,
                  other_remarks: msgtoEmployer,
                })
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
      <div>
        {/* <Header page="disableBottom" /> */}
        <NewHeader/>
      </div>
      <div className="">
        <div
          className="fixed top-0 flex-col items-center justify-center hidden w-5/12 h-full bg-adx-gray lg:flex"
          style={{}}
        >
          <div className="w-2/3">
            <div className="pl-3 mt-2 text-lg font-semibold lg:text-2xl">
              Find the right job with NEX
            </div>
            <form
              className="p-1 mt-2 rounded-4xl bg-adx-white lg:mt-5 lg:mb-24 lg:w-full lg:py-2 "
              method="GET"
              action="/jobs"
              autoComplete="off"
            >
              <SearchIcon className={`mx-2 ${classes.searchIcon}`} />
              <InputBase
                className={`${classes.searchBar} w-80percent`}
                placeholder="Search"
                name="search"
              />
            </form>
            <div className="text-center">
              <p className="mb-2 text-white">For better experience</p>
              <div className="flex justify-center gap-x-2">
                <img
                  className="rounded-lg cursor-pointer h-11 w-36"
                  src={apple_store_badges}
                  alt="..."
                  onClick={() => appDownload('apple')}
                />
                <img
                  className="rounded-lg cursor-pointer h-11 w-36"
                  src={google_play_badges}
                  alt="..."
                  onClick={() => appDownload('android')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="inline-block w-full ">
          <div className="inline-block lg:w-5/12">{/* c */}</div>
          <div className="inline-block w-full px-4 lg:w-7/12">
            {/* <div className ="justify-end hidden my-2 lg:flex">
                                <Button className={classes.btnContained} 
                                onClick ={()=>processSelfApplied({user_employer_id:jobInterview.user_id , job_advertisement_id:jobInterview.id})}
                                disabled ={jobInterview.applied}>{!jobInterview.applied ? "Apply Now" : "Applied"}</Button>
                            </div> */}
            {/* <div className ="grid h-12 grid-cols-12 my-2 gap-x-4 lg:hidden" >
                                <div className ="flex items-center h-full col-span-5">
                                    <Button  onClick={()=>handleClickOpen()} className ={classes.btn1}variant = "contained" startIcon = {<ShareIcon />}>Share</Button>
                                </div>
                                <div className ="flex items-center h-full col-span-7">
                                    <Button onClick ={()=>processSelfApplied({user_employer_id:jobInterview.user_id , job_advertisement_id:jobInterview.id})}
                                    className ={classes.btn2} disabled ={jobInterview.applied}>{!jobInterview.applied ? "Apply Now" : "Applied"}</Button>
                                </div>
                        </div> */}
            <div className="w-full min-h-screen my-2 overflow-auto border rounded-xl bg-adx-darkGray">
              <div className="box-border relative grid w-full grid-cols-3 py-4 mx-auto shadow-lg border-xl rounded-t-md bg-adx-white">
                <div className="grid place-items-center justify-self-center">
                  {jobInterview.company_logo === null ||
                  re.exec(jobInterview.company_logo)[1] === 'pdf' ? (
                    <div className="flex items-center justify-center w-20 h-20 text-3xl text-black rounded-full shadow bg-adx-white">
                      {jobInterview.company_name.charAt(0)}
                    </div>
                  ) : (
                    <img
                      src={jobInterview.company_logo}
                      alt="company_logo"
                      className="object-cover w-20 h-20 rounded-full shadow"
                    />
                  )}
                </div>
                <div className="flex flex-col justify-center col-span-2">
                  {/* <p className ="w-5/6 text-sm font-bold truncate lg:text-base text-adx-darkGreen">{jobInterview.id}</p> */}
                  <p className="w-5/6 text-sm font-bold truncate text-adx-darkGreen lg:text-base">
                    {jobInterview.position_title}
                  </p>
                  <p className="w-5/6 text-sm font-bold truncate lg:text-base">
                    {jobInterview.company_name}
                  </p>
                  {/* <p className = "text-sm font-bold lg:text-base">{id}</p> */}
                  {/* <Button className ={classes.btn1}variant = "contained" startIcon = {<ShareIcon />}>Share</Button> */}
                </div>
                <div className="absolute hidden bottom-2 right-2">
                  <IconButton
                    onClick={() => handleClickOpen()}
                    className={classes.ShareIconButton}
                    size="large"
                  >
                    <ShareIcon />
                  </IconButton>
                  {/* <Button className ={classes.btnShareDesktop}onClick={()=>handleClickOpen()}variant = "contained" startIcon = {<ShareIcon />}>Share</Button> */}
                </div>
              </div>
              <div className="px-4 my-2">
                <p className="text-sm font-bold lg:text-base">Application Status</p>
                <p className="w-5/6 text-sm truncate lg:text-base">
                  {jobInterview.interview_status}{' '}
                </p>
              </div>
              <hr className="mx-4 border-black border-1" />
              <div className="px-4 my-2">
                <p className="text-sm font-bold lg:text-base">Schedule</p>
                <div>
                  <CalendarTodayIcon className="mb-1 transform scale-75 lg:mb-0" />{' '}
                  <p className="inline text-sm text-justify whitespace-pre-line lg:text-base">
                    {moment(jobInterview.date).format(' DD/MM/YYYY')}
                  </p>
                </div>
                <div>
                  <AccessTimeIcon className="mb-1 transform scale-75 lg:mb-0" />{' '}
                  <p className="inline text-sm text-justify whitespace-pre-line lg:text-base">
                    {moment(jobInterview.interview_time, 'hh:mm:ss').format('LT')}
                  </p>
                </div>
                <div>
                  <LocationOnIcon className="mb-1 transform scale-75 lg:mb-0" />
                  <p className="inline text-sm text-justify whitespace-pre-line lg:text-base">
                    {jobInterview.address}
                  </p>
                </div>
              </div>
              <hr className="mx-4 border-black border-1" />

              <div className="px-4 my-2">
                <p className="text-sm font-bold lg:text-base">Notes</p>
                <p className="text-sm text-justify whitespace-pre-line lg:text-base">
                  {jobInterview.message}
                </p>
              </div>

              <hr className="mx-4 border-black border-1" />

              <div className="mb-4">
                <div className="grid w-4/5 h-12 grid-cols-12 mx-auto my-2 gap-x-4">
                  <div className="flex items-center h-full col-span-5">
                    <Button
                      onClick={() => handleClickOpenDeclineInvitation()}
                      className={classes.btn1}
                      variant="contained"
                    >
                      Decline
                    </Button>
                  </div>
                  <div className="flex items-center h-full col-span-7">
                    <Button
                      onClick={() => handleClickOpenAcceptInvitation()}
                      className={classes.btn2}
                    >
                      Accept Invitation
                    </Button>
                  </div>
                </div>
                <div className="flex items-center justify-center w-4/5 mx-auto text-center ">
                  <p className="inline mr-2 whitespace-nowrap">Wish to reschedule?</p>
                  <Button onClick={() => handleClickOpenReschedule()} className={classes.btn3}>
                    Reschedule
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobInterviewDetails
