import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Bugsnag from '@bugsnag/js'

import Button from '@mui/material/Button'
import ShareIcon from '@mui/icons-material/Share'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'

import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import { jobApplicationsById } from 'services/api/job_application'
import apple_store_badges from '../assets/app-store-badge.png'
import google_play_badges from '../assets/google-play-badge.png'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'

const mui = {
  btnShareDesktop: {
    color: '#E2E2E2',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    '&:hover': {
      backgroundColor: '#1B8379',
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
}

const JobApplicationDetails = () => {
  const param = useParams()

  const [jobApplication, setJobApplication] = useState([])
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    getJobApplicationsById(param.id)
  }, [param.id])

  const getJobApplicationsById = async (id) => {
    try {
      const response = await jobApplicationsById(id)
      setJobApplication(response.data)
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setCopied(false)
  }

  // Get image file extension
  let re = /(?:\.([^.]+))?$/

  return (
    <div className="h-screen">
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="lg">
        <div className="flex flex-col justify-between p-8 item-center h-80 lg:w-96">
          <p className="text-lg font-bold text-center">Share this job on social media</p>
          <div className="mb-4">
            <p className="font-bold text-center truncate text-adx-darkGreen">
              {jobApplication.position_title}
            </p>
            <p className="font-bold text-center truncate">{jobApplication.company_name}</p>
          </div>
          <div className="flex justify-between">
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon borderRadius="50%" size={48} />
            </FacebookShareButton>

            <LinkedinShareButton url={window.location.href}>
              <LinkedinIcon borderRadius="50%" size={48} />
            </LinkedinShareButton>

            <TwitterShareButton url={window.location.href}>
              <TwitterIcon borderRadius="50%" size={48} />
            </TwitterShareButton>

            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon borderRadius="50%" size={48} />
            </WhatsappShareButton>
          </div>

          <CopyToClipboard text={window.location.href} onCopy={() => setCopied(true)}>
            <Button sx={mui.btnShareDesktop} color="primary">
              Copy to clipboard
            </Button>
          </CopyToClipboard>
          {copied ? <p className="text-center text-red-500">Copied</p> : null}
        </div>
      </Dialog>
      <div>
        {/* <Header page="disableBottom" /> */}
        <NewHeader/>
      </div>
      <div className="">
        <div
          className="fixed top-0 flex-col items-center justify-center hidden w-5/12 h-full bg-adx-gray lg:flex"
          style={{}}
        >
          <div className="w-2/3">
            <div className="pl-3 mt-2 text-lg font-semibold lg:text-2xl">
              Find the right job with NEX
            </div>
            <form
              className="p-1 mt-2 rounded-4xl bg-adx-white lg:mt-5 lg:mb-24 lg:w-full lg:py-2 "
              method="GET"
              action="/jobs"
              autoComplete="off"
            >
              <SearchIcon className="mx-2" />
              <InputBase className=" w-80percent" placeholder="Search" name="search" />
            </form>
            <div className="text-center">
              <p className="mb-2 text-white">For better experience</p>
              <div className="flex justify-center gap-x-2">
                <img
                  className="rounded-lg cursor-pointer h-11 w-36"
                  src={apple_store_badges}
                  alt="apple badge"
                  onClick={() => appDownload('apple')}
                />
                <img
                  className="rounded-lg cursor-pointer h-11 w-36"
                  src={google_play_badges}
                  alt="google badge"
                  onClick={() => appDownload('android')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="inline-block w-full ">
          <div className="inline-block lg:w-5/12">{/* c */}</div>
          <div className="inline-block w-full px-4 lg:w-7/12">
            <div className="w-full min-h-screen my-2 overflow-auto border rounded-xl bg-adx-darkGray">
              <div className="box-border relative grid w-full grid-cols-3 py-4 mx-auto shadow-lg border-xl rounded-t-md bg-adx-white">
                <div className="grid place-items-center justify-self-center">
                  {jobApplication.company_logo === null ||
                  re.exec(jobApplication.company_logo)[1] === 'pdf' ? (
                    <div className="flex items-center justify-center w-20 h-20 text-3xl text-black rounded-full shadow bg-adx-white">
                      {jobApplication.company_name.charAt(0)}
                    </div>
                  ) : (
                    <img
                      src={jobApplication.company_logo}
                      alt="company_logo"
                      className="object-cover w-20 h-20 rounded-full shadow"
                    />
                  )}
                </div>
                <div className="flex flex-col justify-center col-span-2">
                  <p className="w-5/6 text-sm font-bold truncate text-adx-darkGreen lg:text-base">
                    {jobApplication.position_title}
                  </p>
                  <p className="w-5/6 text-sm font-bold truncate lg:text-base">
                    {jobApplication.company_name}
                  </p>
                </div>
                <div className="absolute hidden bottom-2 right-2">
                  <IconButton
                    onClick={() => handleClickOpen()}
                    sx={mui.ShareIconButton}
                    size="large"
                  >
                    <ShareIcon />
                  </IconButton>
                </div>
              </div>
              <div className="px-4 my-2">
                <p className="text-sm font-bold lg:text-base">Job Details</p>
                <p className="w-5/6 text-sm truncate lg:text-base">
                  RM{jobApplication.min_salary} - RM{jobApplication.max_salary}
                </p>
                <p className="w-5/6 text-sm truncate lg:text-base">
                  {jobApplication.city}, {jobApplication.state}
                </p>
              </div>
              <hr className="mx-4 border-black border-1" />

              <div className="px-4 my-2">
                <p className="text-sm font-bold lg:text-base">Job Description</p>
                <p className="text-sm text-justify whitespace-pre-line lg:text-base">
                  {jobApplication.job_description}
                </p>
              </div>

              <hr className="mx-4 border-black border-1" />

              <div className="px-4 my-2">
                <p className="text-sm font-bold lg:text-base">Job Requirements</p>
                <p className="text-sm text-justify whitespace-pre-line lg:text-base">
                  {jobApplication.job_requirement}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobApplicationDetails
