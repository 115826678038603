import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import ShareIcon from '@mui/icons-material/Share'
import { makeStyles } from '@mui/styles'
import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
// import { authContext } from 'contexts/AuthContext'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import apple_store_badges from '../assets/app-store-badge.png'
import google_play_badges from '../assets/google-play-badge.png'
import Dialog from '@mui/material/Dialog'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import IconButton from '@mui/material/IconButton'
import { jobApplicationsById } from '../../../services/api/job_application'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'

const useStyles = makeStyles({
  btn1: {
    color: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    // justifySelf:"center",
    '&:hover': {
      // color:"#1B8379",
      backgroundColor: 'E2E2E2',
      // textTransform:"uppercase"
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
  btn2: {
    color: '#E2E2E2',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    // justifySelf:"center",
    '&:hover': {
      // color:"#1B8379",
      backgroundColor: '#1B8379',
      // textTransform:"uppercase"
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
  btnContained: {
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    borderRadius: '100px',
    padding: '8px 20px',

    '&:focus': {
      outline: 'none',
      backgroundColor: '#1B8379',
    },
    '&:hover': {
      outline: 'none',
      backgroundColor: '#1B8379',
    },
  },
  btnShareDesktop: {
    color: '#E2E2E2',
    backgroundColor: '#1B8379',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    height: 40,
    width: '100%',
    // justifySelf:"center",
    '&:hover': {
      // color:"#1B8379",
      backgroundColor: '#1B8379',
      // textTransform:"uppercase"
    },
    '&:focus': {
      outline: 'none',
      color: 'none',
      backgroundColor: 'none',
    },
  },
})

const JobResultDetails = (props) => {
  const classes = useStyles()
  const param = useParams()
  // const [jobApplication, setJobAdvertisement] = useState([])
  const [jobApplication, setJobApplication] = useState([])
  const [open, setOpen] = React.useState(false)
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    // getJobAdvertisements(param.id)
    getJobApplicationsById(param.id)
  }, [param.id])

  // const getJobAdvertisements = async (id) => {

  //     try {
  //     const response =  await jobAdvertisements("get",null,id)
  //     console.log(response.data)
  //     setJobAdvertisement(response.data)
  // }
  //     catch(e){
  //         console.log(e)
  //     }

  // }
  const getJobApplicationsById = async (id) => {
    try {
      const response = await jobApplicationsById(id)
      // console.log(response.data)
      setJobApplication(response.data)
    } catch (e) {
      // console.log(e)
    }
  }

  const appDownload = (app) => {
    if (app === 'apple') {
      window.open('https://apps.apple.com/my/app/adnexio-jobs/id1500510440', '_blanks')
    } else if (app === 'android') {
      window.open('https://play.google.com/store/apps/details?id=org.adnexio.jobs', '_blanks')
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
    setCopied(false)

    // setSelectedValue(value);
  }

  return (
    <div className="h-screen">
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="lg">
        {/* <EmailShareButton url={window.location.href}> Share in Email</EmailShareButton> */}
        <div className="item-center flex h-80 flex-col justify-between p-8 font-poppins lg:w-96">
          <p className="text-center text-lg font-bold">Share this job on social media</p>
          <div className="mb-4">
            <p className="truncate text-center font-bold text-adx-darkGreen">
              {jobApplication.position_title}
            </p>
            <p className="truncate text-center font-bold">{jobApplication.company_name}</p>
          </div>
          <div className="flex justify-between">
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon borderRadius="50%" size={48} />
            </FacebookShareButton>

            <LinkedinShareButton url={window.location.href}>
              <LinkedinIcon borderRadius="50%" size={48} />
            </LinkedinShareButton>

            <TwitterShareButton url={window.location.href}>
              <TwitterIcon borderRadius="50%" size={48} />
            </TwitterShareButton>

            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon borderRadius="50%" size={48} />
            </WhatsappShareButton>
          </div>

          <CopyToClipboard text={window.location.href} onCopy={() => setCopied(true)}>
            <Button className={classes.btnShareDesktop} color="primary">
              Copy to clipboard{' '}
            </Button>
          </CopyToClipboard>
          {copied ? <p className="text-center text-red-500">Copied</p> : null}
        </div>
      </Dialog>
      <div>
        {/* <Header page="disableBottom" /> */}
        <NewHeader />
      </div>
      <div className="">
        <div
          className="fixed top-0 hidden h-full w-5/12 flex-col items-center justify-center bg-adx-gray lg:flex"
          style={{}}
        >
          <div className="w-2/3">
            <div className="mt-2 pl-3 text-lg font-semibold lg:text-2xl">
              Find the right job with NEX
            </div>
            <form
              className="mt-2 rounded-4xl bg-adx-white p-1 lg:mt-5 lg:mb-24 lg:w-full lg:py-2 "
              method="GET"
              action="/jobs"
              autoComplete="off"
            >
              <SearchIcon className={`mx-2 ${classes.searchIcon}`} />
              <InputBase
                className={`${classes.searchBar} w-80percent`}
                placeholder="Search"
                name="search"
              />
            </form>
            <div className="text-center">
              <p className="mb-2 text-white">For better experience</p>
              <div className="flex justify-center gap-x-2">
                <img
                  className="h-11 w-36 cursor-pointer rounded-lg"
                  src={apple_store_badges}
                  alt="..."
                  onClick={() => appDownload('apple')}
                />
                <img
                  className="h-11 w-36 cursor-pointer rounded-lg"
                  src={google_play_badges}
                  alt="..."
                  onClick={() => appDownload('android')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="inline-block w-full ">
          <div className="inline-block lg:w-5/12">{/* c */}</div>
          <div className="inline-block w-full px-4 lg:w-7/12">
            {/* <div className ="justify-end hidden my-2 lg:flex">
                                <Button className={classes.btnContained} 
                                onClick ={()=>processSelfApplied({user_employer_id:jobApplication.user_id , job_advertisement_id:jobApplication.id})}
                                disabled ={jobApplication.applied}>{!jobApplication.applied ? "Apply Now" : "Applied"}</Button>
                            </div> */}
            {/* <div className ="grid h-12 grid-cols-12 my-2 gap-x-4 lg:hidden" >
                                <div className ="flex items-center h-full col-span-5">
                                    <Button  onClick={()=>handleClickOpen()} className ={classes.btn1}variant = "contained" startIcon = {<ShareIcon />}>Share</Button>
                                </div>
                                <div className ="flex items-center h-full col-span-7">
                                    <Button onClick ={()=>processSelfApplied({user_employer_id:jobApplication.user_id , job_advertisement_id:jobApplication.id})}
                                    className ={classes.btn2} disabled ={jobApplication.applied}>{!jobApplication.applied ? "Apply Now" : "Applied"}</Button>
                                </div>
                        </div> */}
            <div className="my-2 min-h-screen w-full overflow-auto rounded-xl border bg-adx-darkGray">
              <div className="border-xl relative mx-auto box-border grid w-full grid-cols-3 rounded-t-md bg-adx-white py-4 shadow-lg">
                <div className="grid place-items-center justify-self-center">
                  <img
                    src={jobApplication.company_logo}
                    alt="..."
                    className="h-20 w-20 rounded-full object-cover"
                  />
                </div>
                <div className="col-span-2 flex flex-col justify-center">
                  {/* <p className ="w-5/6 text-sm font-bold truncate lg:text-base text-adx-darkGreen">{jobApplication.id}</p> */}
                  <p className="w-5/6 truncate text-sm font-bold text-adx-darkGreen lg:text-base">
                    {jobApplication.position_title}
                  </p>
                  <p className="w-5/6 truncate text-sm font-bold lg:text-base">
                    {jobApplication.company_name}
                  </p>
                  {/* <p className = "text-sm font-bold lg:text-base">{id}</p> */}
                  {/* <Button className ={classes.btn1}variant = "contained" startIcon = {<ShareIcon />}>Share</Button> */}
                </div>
                <div className="absolute bottom-2 right-2 hidden">
                  <IconButton
                    onClick={() => handleClickOpen()}
                    className={classes.ShareIconButton}
                    size="large"
                  >
                    <ShareIcon />
                  </IconButton>
                  {/* <Button className ={classes.btnShareDesktop}onClick={()=>handleClickOpen()}variant = "contained" startIcon = {<ShareIcon />}>Share</Button> */}
                </div>
              </div>
              <div className="my-2 px-4">
                <p className="text-sm font-bold lg:text-base">Application Result</p>
                <p className="w-5/6 truncate text-sm lg:text-base">{jobApplication.interview} </p>
              </div>
              <hr className="border-1 mx-4 border-black" />

              <div className="my-2 px-4">
                <p className="text-sm font-bold lg:text-base">Notes</p>
                <p className="whitespace-pre-line text-justify text-sm lg:text-base">
                  {jobApplication.interview_message}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobResultDetails
